import { graphql } from "gatsby";
import { t } from "i18next";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import ButtonGradient from "../components/ButtonGradient";
import ContactForm from "../components/ContactForm";
import Layout from "../components/Layout";
import ProductCard from "../components/ProductCard";
import SEO from "../components/SEO";
import TestimonialCard from "../components/TestimonialCard";

// markup
const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title={t("Contact")} />
      <Layout>
        <div className="flex flex-col lg:flex-row gap-14 text-center lg:text-left ">
          <section className="lg:basis-1/2">
            <header className="mb-10">
              <h2 className="mb-3">
                <Trans>Contact</Trans>
              </h2>
              <p>
                <Trans>
                  We are a Polish company and we will gladly help your team to
                  scale AI projects. Enjoy a ☕ in our Warsaw office or connect
                  remotely to talk about the exciting artificial intelligence.
                </Trans>
              </p>
            </header>
            <div className="flex flex-col gap-4">
              <div>
                WEimpact Sp. z o.o.
                <br />
                NIP: PL 7010951924
              </div>
              <div>
                <Trans>3/G Jana Janowicza Street</Trans>
                <br />
                <Trans>10-692 Olsztyn Poland EU</Trans>
              </div>
              <div>
                <Trans>Phone</Trans>: +48 797-111-614
              </div>
            </div>
          </section>
          <section className="lg:basis-1/2">
            <ContactForm />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
